import styled from 'styled-components'

export const MainButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;
  background-color: #fc5b0e;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  border: 0;
  transition: .3s all ease-out;
  @media (max-width: 991px) {
    padding: 0 15px;
    margin-top: 15px;
  }   
  &:hover {
    /* background: linear-gradient(#f89931, #fc5b0e) */
    box-shadow: inset 0px 5px 35px #f89931;
  }
`

export const H1 = styled.div`
  font-weight: bold;
  font-family: montserrat-bold;
  margin: 0;
  font-size: 30px;
  line-height: 1.27;
  color: #2d2d2d;
`