import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Seo from '../../components/seo/page';
import Layout from '../../components/layouts'
import Info from '../../components/pages/products/Info'
import Capabilities from '../../components/pages/products/Capabilities'
import Benefits from '../../components/pages/products/Benefits'
import Menu from '../../components/pages/products/Menu';

import {
    Container
} from '../../components/styledComponents'

const Title = styled.h1`
  margin-bottom: 40px;
  @media (max-width: 991px) {
    margin-bottom: 15px;
  }
`

export default class Contacts extends Component {

    componentDidMount() {
        document.body.style.background = "#fff"
    }

    getData(nodes, type) {

        let out = nodes.filter((el, idx) => {
            return el.node.frontmatter.block === type;
        });

        return out[0].node;
    }

    render() {
        const { data } = this.props;
        const nodes = data.allMarkdownRemark.edges;
        return (
            <Layout>
                <Seo title={'SharpToll'} meta={{
                    description: 'Российское программное обеспечение для управления дорожным движением на автомагистралях. ',
                    keywords: '',
                    'og:url': 'products/sharptoll'
                }} />
                <Container>
                    <Title>Продукты</Title>
                    <Menu current="sharptoll" />
                    <Info data={this.getData(nodes, 'info')} />
                    <Capabilities data={this.getData(nodes, 'capabilities')} />
                    <Benefits data={this.getData(nodes, 'benefits')} />
                </Container>
            </Layout>
        )
    }

}

export const query = graphql`
  query getProductDataSharp {
    allMarkdownRemark (
      filter: {
        frontmatter: {section: {eq: "sharpToll"}}
      }
      sort: {fields: [frontmatter___main], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            page
            section
            title
            index
            main
            block
          }
          html
        }
      }
    }
  }
` 